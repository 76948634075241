<template>
  <div class="product-detail">
    <v-row>
      <v-col v-if="product" cols="12" sm="12" md="4">
        <div class="gallery_container" v-if="showImages">
          <hooper group="group1" class="hooper_large_image">
            <slide v-for="img in images.large_size" :key="img.id">
              <img
                class="slide_image_large"
                :src="img.url"
                alt="product_image"
              />
            </slide>
          </hooper>

          <hooper
            v-if="images.normal_size.length > 1"
            group="group1"
            class="hooper_small_image"
            :itemsToShow="3"
          >
            <slide v-for="img in images.normal_size" :key="img.id">
              <img
                class="slide_image_small"
                :src="img.url"
                alt="product_image"
              />
            </slide>

             
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
          </hooper>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="8">
        <div class="meta-container">
          <div class="errorMessage-container" v-if="showImportError">
            <div class="material-icons error-icon">cancel</div>
            <div>
              Ops! something happened
              <span>{{ importErrorMessage }}</span>
            </div>
          </div>
          <div class="modal-successmessage" v-if="showImportSuccess">
            <div class="material-icons success-icon">check_box</div>
            <div>Product imported successfully!</div>
          </div>
           
          <div v-if="product">
            <div class="mb-10">
              <h1 v-if="product.title" class="product-name">
                {{ product.title }}
              </h1>
              <div class="delivery-detail-wrapper">
                 
                <span v-if="product.supplierName" class="from"
                  >By: {{ product.supplierName }}</span
                >
              </div>
              <div class="meta-data mt-10">
                <div class="each d-flex">
                  <span class="title"
                    >Price: Min €{{ product.price }} to Max €{{
                      product.maxPrice
                    }}</span
                  >
                  <span class="values-container ml-auto mr-15">
                    Shipping rate: Different based on destination</span
                  >
                </div>
              </div>
            </div>
            <div class="product-description mb-15">
              <h1 class="product-name">Product description</h1>
              <div v-if="product.description">
                <div
                  v-if="readMore"
                  class="title"
                  :inner-html.prop="product.description | truncate(450, '...')"
                ></div>
                <div
                  v-else
                  class="title"
                  :inner-html.prop="product.description"
                ></div>
              </div>
            </div>
            <div
              v-if="!readMore"
              class="read-more"
              @click="readMore = !readMore"
            >
              Read less
            </div>
            <div v-else class="read-more" @click="readMore = !readMore">
              Read more
            </div>

            <div class="meta-data mt-5">
              <div class="each mb-10">
                <span class="title">Processing Time</span>
                <span class="values-container"> 1-2 business days </span>
              </div>
              <p class="paragraph">
                Most orders are shipped the same day if placed before 3:00 pm
                PST during normal business hours. Our normal business hours are
                M-F 9:00 am to 5:00 pm PST. Orders are not shipped on weekends
                or holidays.
              </p>

              <div class="product-description mt-15">
                <h1 class="product-name">Return Policy</h1>
                <p class="title">
                  If for any reason you are not happy with your purchase, you
                  may return the item(s) within 15 days of receiving the order.
                  Please note that monogrammed, personalized, special-order
                  items, and items damaged through normal wear and tear are not
                  eligible for return.
                </p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
 
import SuplierProduct from "@/services/SupplierProduct";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "SingleProduct",
  data() {
    return {
      thumbs: [],
      normal_sizes: [],
      zoomerOptions: {
        zoomFactor: 2, // scale for zoomer
        pane: "container-round", // three type of pane ['pane', 'container-round', 'container']
        hoverDelay: 300, // how long after the zoomer take effect
        namespace: "zoomer", // add a namespace for zoomer component, useful when on page have mutiple zoomer
        move_by_click: false, // move image by click thumb image or by mouseover
        scroll_items: 3, // thumbs for scroll
        choosed_thumb_border_color: "#ffffff", // choosed thumb border color
        scroller_button_style: "fill",
        scroller_position: "bottom",
        zoomer_pane_position: "right",
      },
      product: "",
      readMore: true,
      images: {},
      imageslist: [],
      showImages: true,
      variants: "",
      showVariants: false,
      importErrorMessage: "",
      showImportError: false,
      showImportSuccess: false,
      index: null,
    };
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    truncate: function (text, length, clamp) {
      return text.length > length ? text.slice(0, length) + clamp : text;
    },
  },
  async mounted() {
    await this.getProduct(
      this.$route.params.id,
      this.$route.query.accessToken,
      this.$route.query.source,
      this.$route.query.sourceName
    );
  },

  methods: {
    async getProduct(id, accessToken, source, sourceName) {
      try {
        const response = await SuplierProduct.getSingleProduct(
          id,
          accessToken,
          source,
          sourceName
        );

        const data = await response.json();
        this.product = data.products;
        this.i = 1;
        for (const val of this.product.productImages) {
          this.i = this.i + 1;
          this.thumbs.push({
            id: this.i,
            url: val.thumbnailImage,
          });
          this.normal_sizes.push({
            id: this.i,
            url: val.image,
          });
        }
        this.showImages = true;
        this.images = {
          thumbs: this.thumbs,
          normal_size: this.normal_sizes,
          large_size: this.normal_sizes,
        };
      } catch (error) {
        this.showImportError = true;
        this.importErrorMessage = "Please try again later";
        // console.log(error);
      }
    },

    getFlag(iso) {
      var flag = require.context("@/assets/img/flags", false, /\.svg$/);
      return flag("./" + iso + ".svg");
    },
    calculateProfit(cost, retailerPrice) {
      return cost - retailerPrice;
    },
    findSize(options) {
      var index = options.findIndex((p) => p.name == "Size");
      if (index != "-1") {
        return options[index].value;
      } else {
        return "Undefined";
      }
    },
    findColor(options) {
      var index = options.findIndex((p) => p.name == "Color");
      if (index != "-1") {
        return options[index].value;
      } else {
        return "Undefined";
      }
    },
    findMaterial(options) {
      var index = options.findIndex((p) => p.name == "Material");
      if (index != "-1") {
        return options[index].value;
      } else {
        return "Undefined";
      }
    },
  },
};
</script>
<style lang="scss">
.product-detail {
  max-width: 1600px;
  margin: 0 auto;
}
.gallery-container {
  padding: 30px;
  padding-top: 0;
}
.zoomer-base-container {
  width: 100% !important;
}
.preview-box {
  width: 100% !important;
  border-radius: 10px !important;
  margin-bottom: 30px !important;
}
.thumb-list {
  width: 100% !important;

  img {
    width: 100px;
    max-height: 100px !important;
    border-radius: 10px !important;
  }
}
.zoomer-control {
  opacity: 0.7 !important;
  width: unset !important;
}
.modal-dialog {
  max-width: 700px !important;
}
.variant-image {
  width: 50px;
  border-radius: 5px;
  box-shadow: 0 20px 50px 10px rgba(29, 41, 54, 0.1);
}
.product-description {
  .title {
    font-family: "Open Sans", Tahoma !important;
    font-size: 1.4rem !important;
    font-weight: 600 !important;
  }
}
.product-description img{
width: 200px;
height: auto;
margin: 10px;
border-radius: 10px;
}
.shipping-pricing {
  .flag {
    width: 30px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .country {
    font-size: 14px;
    font-weight: 600;
    color: #435160;
  }
}
.read-more {
  font-size: 14px;
  font-weight: 600;
  color: #008f4a;
  cursor: pointer;
}
</style>
